//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imgdown from "@/assets/icon-down.png";
import imgdowngray from "@/assets/icon-down-gray.png";
import imgdate from "@/assets/icon-date.png";
import imgsearch from "@/assets/icon-search.png";
import imgfilter from "@/assets/icon-filter.png";
export default {
  name: "storeDailyReportShareIndex",
  data() {
    return {
      businessChannelOptions: [
        {
          label: "直营正价",
          value: "202",
        },
        {
          label: "直营奥莱",
          value: "203",
        },
        {
          label: "加盟",
          value: "201",
        },
      ],
      imgfilter,
      imgdown,
      imgdate,
      imgdowngray,
      imgsearch,
      dailyList: [],
      title:'',
      sumEnumInfo: {
        storeNum: 0,
        yearTarget: 0,
        yearSum: 0,
        yearCompleteRate: 0,
      },
    };
  },
  created() {
    this.getDailyList();
  },
  methods: {
    getManagerList() {
      const that = this;
      that.sumEnumInfo = {
        storeNum: 0,
        yearTarget: 0,
        yearSum: 0,
        yearCompleteRate: 0,
      }
      var postData = [
        {
          PARAMETER: "Z0CALDAY_MVIM_001",
          SIGN: "I",
          OPTION: "BT",
          LOW: that.dailyList[0] && that.dailyList[0].datetime,
          HIGH: that.dailyList[0] && that.dailyList[0].datetime,
        },
      ];
      this.dailyList.map((v) => {
        that.sumEnumInfo.storeNum++
        that.sumEnumInfo.yearSum += Number(v.yearSumSales)
        postData.push({
          PARAMETER: "Z0CUSTOMER_MVOO_001",
          SIGN: "I",
          OPTION: "EQ",
          LOW_FLAG: "1",
          LOW: v.storeId,
          HIGH: "",
        });
      });
      
      this.axios
        .post("/consumer/bw/BwApi/BWP_ChildSaleList", postData)
        .then((bwRes) => {
          that.showManagerPopover = true;
          console.log("BWP_ChildSaleList", bwRes.data, postData);
          if (bwRes.data && bwRes.data.data && bwRes.data.data.length > 0) {
            bwRes.data.data.map(v=>{
              that.sumEnumInfo.yearTarget += Number(v.YD_AmountIndex)
            })
            that.sumEnumInfo.yearCompleteRate = this.getRate(that.sumEnumInfo.yearSum,that.sumEnumInfo.yearTarget * 10000,1)
          }else{
            that.sumEnumInfo.yearTarget = 0
            that.sumEnumInfo.yearCompleteRate = 0
          }
          that.sumEnumInfo.yearSum = that.getNumber(that.sumEnumInfo.yearSum/10000,1)
        });
    },
    getDailyList() {
      const that = this;
      this.axios
        .get("/consumer/wx/cp/appchat/shareStoreDaySaleListByShareId?id="+that.$route.query.dailyReportShareId)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.data) {
            res.data.data.storeDaySaleList.map((v) => {
              v.businessChannelName =""
              that.businessChannelOptions.map(item=>{
                if(item.value == v.businessChannel){
                  v.businessChannelName = item.label
                }
              })
              v.labelNames = v.labelNames && v.labelNames.split(',') || []
              v.open = false;
              v.goodsOrderRate =
                v.goodsNum == 0 || v.ordersNum == 0
                  ? 0
                  : (v.goodsNum / v.ordersNum).toFixed(2);
            });
            that.dailyList = res.data.data.storeDaySaleList;
            that.getManagerList()
            if(that.dailyList.length > 0){
              window.document.title = that.dailyList[0].saleManager + that.dailyList[0].datetime + "日报";
            }
          }
        });
    },
    getNumber(val){
      let num = Number(val)
      let str = '0'
      if(isNaN(num) || num == 'NaN' || num == Infinity || num == -Infinity){
        return '0'
      }else{
        return Math.floor(num * Math.pow(10,num < 10000 ? 1 : 0)) / Math.pow(10,num < 10000 ? 1 : 0)   
      }
    },
    getRate(value,total){
      if(isNaN(total) || total == 'NaN' || total == Infinity || total == -Infinity){
        return '-'
      }
      if(total == 0){
        return '0'
      }
      let val = (parseFloat(value/total)*100).toFixed(1);
      if(isNaN(val) || val == 'NaN' || val == Infinity || val == -Infinity){
        return '-'
      }else{
        return val+'';
      }
    },
  },
};
